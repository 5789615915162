import React, { useState } from "react";
import "./about-us.scss";
import "./about-us-animation.scss";
import "../../../styles/shared.scss";
import "../../../styles/shared/page-animations.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import image from "../../../../assets/images/image-about-us.jpg";
import ProtonButton from "../../shared/ProtonButton";
import { connect } from "react-redux";

function AboutUs({ display, direction, lang }) {
  const [en] = useState({
    title: "About us",
    text: "At Proton we believe in what hard work and dedication can achieve, these are the qualities that embody our company culture which we apply every day to bring our goals to life. It can also be used to describe the UAE and Abu Dhabi, a country that rose out of the desert to become a shining beacon of progress, science, and industry in the Middle East.",
    button: "MORE ABOUT COMPANY",
  });
  const [ar] = useState({
    title: "عن الشركة",
    text: "في شركة بروتون نؤمن بما يمكن أن يحققه العمل الجاد الثابت ، فهذه هي الصفات التي تجسد ثقافة شركتنا التي نطبقها كل يوم لتحقيق أهدافنا. و ينطبق  أيضًا هذا الوصف على الإمارات العربية المتحدة وأبو ظبي ، الدولة التي أقيمت على الصحراء لتصبح منارة مشرقة للتقدم والعلوم والصناعة في الشرق الأوسط. هذا هو أحد الأسباب العديدة التي دفعتنا إلى وضع أسس بروتون هنا ، في مكان يسعى لتحقيق التميز في كل خطوة أو مشروع.",
    button: "عن الشركة",
  });

  return (
    <>
      <div className={`about-us-page about-page-scroll-${direction}`}>
        <div className="about-us-layout align-items-center">
          <Container>
            <Row>
              <Col lg={5} sm={12}>
                <div className="page-image-container">
                  <img
                    src={image}
                    className="d-inline-block align-top about-image"
                    alt="About Proton Innovations"
                  />
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <h1>{lang === "EN" ? en.title : ar.title}</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} md={12}>
                      <p>{lang === "EN" ? en.text : ar.text}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="page-more-button">
                        <ProtonButton
                          label={lang === "EN" ? en.button : ar.button}
                          redirectPath="/company"
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(AboutUs);
