import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./slide-one.scss";
import "./slide-one-animation.scss";
import Container from "react-bootstrap/Container";
import image from "../../../../assets/images/image-rnd1.jpg";
import { connect } from "react-redux";

function SlideOne({ direction, lang }) {
  const [en] = useState({
    text: "However, the scope of our research and development activities does not end there, our experimentation also focuses on digital signals and their industrial applications. Our efforts are also dedicated to the development of image and data processing systems as well as mathematical modeling and real-time hardware-in-the-loop simulation which are used in automotive systems, air-traffic, robotics, and power systems. ",
  });
  const [ar] = useState({
    text: "ومع ذلك ، فإن نطاق أنشطة البحث والتطوير الخاصة بنا لا تنتهي عند هذا الحد ، وتركز تجربتنا أيضًا على الإشارات الرقمية وتطبيقاتها الصناعية. جهودنا مكرسة أيضًا لتطوير أنظمة معالجة الصور والبيانات بالإضافة إلى النمذجة الرياضية وعمليات محاكاة لاختبار النظم في الوقت الفعلي التي تُستخدم في أنظمة السيارات والتحكم في الحركة الجوية والروبوتات وأنظمة الطاقة.",
  });

  return (
    <>
      <div className={`rnd-slide-one rnd-header-scroll-${direction}`}>
        <div className="rnd-slide-one-layout align-items-center">
          <Container>
            <Row className="justify-content-md-center">
              <Col sm={12} lg="7">
                <h3>{lang === "EN" ? en.text : ar.text}</h3>
              </Col>
              <Col lg="1"></Col>
              <Col sm={12} lg="4">
                <div className="d-flex page-image-container">
                  <img
                    src={image}
                    className="d-inline-block align-top about-image"
                    alt="RND Proton development activities"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(SlideOne);
