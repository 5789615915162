import React from "react";
import "../../styles/shared/proton-button.scss";
import {useHistory} from "react-router-dom";

export default function ProtonButton({ label, redirectPath }) {
    let history = useHistory();

    return (
        <>
            <a className="proton-button"
               type="button"
               onClick={() => history.push(redirectPath)}
            >
                { label }
            </a>
        </>
    );
}