import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./about.scss";
import "./about-animation.scss";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";

function About({ direction, lang }) {
  const [en] = useState({
    topText:
      "Our team also specializes in creating custom solutions based on the needs of your business. We have a diverse team of inhouse developers who specialize in a variety of programming languages, namely C++, PHP, Ruby, React, Swift, Python. The diversity of their skill sets and programming knowledge allows them to create a solution that will fit the client’s needs like a well-tailored bespoke suit.",
    leftText:
      "We can help you set up your whole infrastructure with the help of our experts as well as offer you a place to store your data if you choose our Cloud hosting services.",
    rightText:
      "Our IT security consultants can help you secure your already existing systems and infrastructure or help you build one from the ground up with security serving as one of its cornerstones. ",
  });
  const [ar] = useState({
    topText:
      "يتخصص فريقنا أيضًا في إنشاء حلول مخصصة بناءً على احتياجات عملك. لدينا فريق متنوع من المطورين الداخليين المتخصصين في مجموعة متنوعة من لغات البرمجة ، وهي C ++ و PHP و Ruby و React و Swift و Python. إن تنوع مجموعات مهاراتهم ومعرفتهم بالبرمجة يتيح لهم إنشاء حل يناسب احتياجات العميل مثل البدلة المصممة جيدًا.",
    leftText:
      "يمكننا مساعدتك في إعداد البنية التحتية بالكامل بمساعدة خبرائنا وكذلك نقدم لك مكانًا لتخزين بياناتك إذا اخترت خدمات الاستضافة السحابية لدينا.",
    rightText:
      "يمكن لمستشاري أمن تكنولوجيا المعلومات لدينا مساعدتك في تأمين الأنظمة والبنية التحتية الموجودة بالفعل لديك أو مساعدتك في بناء واحدة من الألف إلى الياء مع توفير الأمان كأحد ركائزها.",
  });

  return (
    <>
      <div className={`services-about services-scroll-${direction}`}>
        <div className="page-layout d-flex align-items-center">
          <Container>
            <Row>
              <Col sm={12} lg="12">
                <h3>{lang === "EN" ? en.topText : ar.topText}</h3>
              </Col>
              <Col sm={12} lg="5">
                <p>{lang === "EN" ? en.leftText : ar.leftText}</p>
              </Col>
              <Col sm={0} lg="2"></Col>
              <Col sm={12} lg="5">
                <p>{lang === "EN" ? en.rightText : ar.rightText}</p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(About);
