import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./slide-three.scss";
import "./slide-three-animation.scss";
import "./slide-three-animation-reversed.scss";
import Container from "react-bootstrap/Container";
import image from "../../../../assets/images/image-rnd3.jpg";
import { connect } from "react-redux";

function SlideThree({ direction, lang }) {
  const [en] = useState({
    text: "We provide multiple services including full-scale production, technology transfer as well as training your personnel on how to handle and run specific technologies.  ",
  });
  const [ar] = useState({
    text: "كل هذا ممكن بفضل فريق المتخصصين لدينا. يأتي باحثونا من خلفيات متنوعة ويحملون مؤهلات كاملة بما في ذلك الدكتوراه في مجموعة متنوعة من المجالات العلمية. وهذا يجعلهم مجهزين بشكل فريد لمواجهة أي تحد يواجهونه.",
  });

  return (
    <>
      <div
        className={`rnd-slide-three rnd-header-scroll-${direction}${
          lang === "AR" ? "-reversed" : ""
        }`}>
        <div className="rnd-slide-three-layout d-flex align-items-center">
          <Container>
            <Row className="justify-content-md-between">
              <Col sm={12} lg="8">
                <img
                  src={image}
                  className="rnd-specific-technologies-image"
                  alt="RND Proton specific technologies"
                />
              </Col>
              <Col sm={12} lg="4">
                <h3>{lang === "EN" ? en.text : ar.text}</h3>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(SlideThree);
