import React, { useState } from "react";
import "./PricingTableDesktop.scss";
import TableBodyRow from "./TableBodyRow/TableBodyRow";
import TableHeadRow from "./TableHeadRow";
import ContactFormPopup from "../ContactForm/ContactFormPopup";

export default function PricingTableDesktop() {
  let numberOfButtons = [1, 2, 3];
  let tableRows = [
    {
      name: "Project management",
      essentials: true,
      business: true,
      platform: true,
      firstRow: true,
      tooltip:
        "All essential project management tools, such as Gantt chart, task and time tracking, calendars, reporting, and more. Tools for every phase of your project integrated into one software.",
    },
    {
      name: "Agile Tools",
      essentials: true,
      business: true,
      platform: true,
      firstRow: false,
      tooltip:
        "Agile tools that are suitable for both Scrum & Kanban methodologies, which allows you to work with sprints, backlogs, tasks in backlog, and their statuses. Classical and agile management combined in one solution.",
    },
    {
      name: "WBS & Mind Maps",
      essentials: true,
      business: true,
      platform: true,
      firstRow: false,
      tooltip:
        "Work Breakdown Structure (WBS) allows you to decompose projects into small manageable sections that are easy to assign, also allows you to accurately estimate needed time and resources, and track their completion. Unlike WBS, Mind Maps are designed more for the initiation and analysis phase of a project when it is not yet necessary to create tasks and collaborate but, above all, to analyze and think about all parts of the project or process.",
    },
    {
      name: "Resource Management",
      essentials: false,
      business: true,
      platform: true,
      firstRow: false,
      tooltip:
        "Drag & drop visual planning with regards to the real-time capacities and competencies of workers across all projects. Managers save time thanks to easy-to-use & visual capacity planning.",
    },
    {
      name: "Expert PM tools",
      essentials: false,
      business: true,
      platform: true,
      firstRow: false,
      tooltip:
        "Tools for professional-level project management. Use Risk Management, Diagram, Earned Value, or Test Cases to make your projects truly professional.",
    },
    {
      name: "HelpDesk & Ticketing",
      essentials: false,
      business: false,
      platform: true,
      firstRow: false,
      tooltip:
        "Easily streamline all customer processes. Set your SLAs and provide your clients with top-quality support and increase their satisfaction using your Help Desk.",
    },
    {
      name: "B2B CRM",
      essentials: false,
      business: false,
      platform: true,
      firstRow: false,
      tooltip:
        "Manage your sales activities and deliver your projects. B2B CRM allows your sales team to track opportunities from a lead to a contract, generates performance statistics, and helps to boost your overall sales performance.",
    },
  ];
  return (
    <div className="pricing-table">
      <table className="table mt-3">
        <thead>
          <TableHeadRow />
        </thead>
        <tbody>
          {tableRows.map((tableRow, index) => {
            return (
              <TableBodyRow
                key={index}
                rowTitle={tableRow.name}
                essentialsColumnCheck={tableRow.essentials}
                businessColumnCheck={tableRow.business}
                platformColumnCheck={tableRow.platform}
                firstRow={tableRow.firstRow}
                tooltip={tableRow.tooltip}
              />
            );
          })}
          <tr>
            <th scope="row"></th>
            {numberOfButtons.map((index) => {
              return (
                <th
                  key={index}
                  className={`buttons-row ${
                    index === 2 ? "business-column-button-container" : ""
                  }`}
                >
                  <a className="table-button" href="#trial-section">
                    Buy
                  </a>
                </th>
              );
            })}
            <td className="enterprise-column-button-container buttons-row">
              <ContactFormPopup label="Let's talk" buttonClassName="table-button" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
