export function changeLang(lang) {
  if (lang === "EN") {
    return (dispatch) => {
      localStorage.setItem("lang", "AR");
      dispatch({ type: "AR_LANG" });
    };
  } else if (lang === "AR") {
    return (dispatch) => {
      localStorage.setItem("lang", "EN");
      dispatch({ type: "EN_LANG" });
    };
  }
}
