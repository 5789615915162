import React, { useState } from "react";
import ContactForm from "../ContactForm/ContactForm";

export default function ContactFormPopup({ label, buttonClassName }) {
  const [contactFormIsVisible, setContactFormIsVisible] = useState(false);
  function popupOpenButtonClickHandler(event) {
    event.preventDefault();
    setContactFormIsVisible(true);
  }

  function popupCloseButtonClickHandler(event) {
    event.preventDefault();
    setContactFormIsVisible(false);
  }
  return (
    <div className="contact-form">
      <a
        onClick={popupOpenButtonClickHandler}
        className={ buttonClassName || "proton-button" }
        href=""
      >
        {" "}
        {label}
      </a>

      {contactFormIsVisible && (
        <ContactForm contactFromCloseClickHandler={popupCloseButtonClickHandler} />
      )}
    </div>
  );
}
