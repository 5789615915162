import React from "react";
import "./CertifiedPartner.scss";
import EasyLogo from "../../../../assets/images/easy-logo.png";
import CertifiedPartnerLogo from "../../../../assets/images/certified-partner-logo.png";

export default function CertifiedPartner() {
  return (
    <div className="certified-partner-section">
      <div className="row w-100 flex-column align-items-center pt-4">
        <img src={EasyLogo} atl="Easy Project logo" className="img-fluid py-3" />
        <img
          src={CertifiedPartnerLogo}
          atl="Cetrified partner logo"
          className="img-fluid"
        />
      </div>
    </div>
  );
}
