import React, { useState } from "react";
import "./header.scss";
import "./header-animation.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProtonButton from "../../shared/ProtonButton";
import image from "../../../../assets/images/image - services2.jpg";
import ScrollDownButton from "../../shared/ScrollDownButton";
import ButtonBack from "../../shared/ButtonBack/ButtonBack";

export default function Header({ direction }) {
  const [lang] = useState({
    header: "NECTAIN",
    subHeader: "LOW-CODE platform for BPM, PM and DMS",
    text: "Innovative software for business process automation. Fully customizable modular solutions for any type of business. NECTAIN is a combination of BPM, DMS, CRM and PM, able to manage your entire organisation’s digital ecosystem. If you need your processes automized — your projects, documents, or customers managed — NECTAIN is the solution.",
  });

  return (
    <>
      <div className={`tesey-header tesey-scroll-${direction}`}>
        <div className="page-layout white-page d-flex align-items-center">
          <Container>
            <Row>
              <Col sm={12} lg="9">
                <h1>{lang.header}</h1>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg="5">
                <h4>{lang.subHeader}</h4>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg="7">
                <p>{lang.text}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg="8">
                <ProtonButton
                  label="START FREE TRIAL"
                  redirectPath="/contact"
                />
              </Col>
            </Row>
          </Container>
          <div className="tesey-header-image-container d-flex">
            <img
              src={image}
              className="services-services-it-infrastructure-image"
              alt="RND Proton It Infrastructure"
            />
          </div>
        </div>
        <ScrollDownButton arrowOnly={true} />
        <ButtonBack redirectPath={"/products"} />
      </div>
    </>
  );
}
