import React, { useState } from "react";
import './tools.scss'
import './tools-animation.scss'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import image from "../../../../assets/images/tesey.jpg";

export default function Header({direction}) {
    const [lang] = useState({
        services: [
            { name: 'Press Lock', text: 'Block any illegitimate process, even on behalf of the Administrator' },
            { name: 'Bans on Change', text: 'Prevent any changes of protected executable files, their environment and associated data' },
            { name: 'Authorization', text: 'Crypto strong two level authorization and authentication of user and end user computing device' },
            { name: 'Information Access Control', text: 'Operational control of the process access to any information' },
        ]
    });

    return (
        <>
            <div className={ `tesey-tools tesey-scroll-${direction}` }>
                <div className="page-layout white-page d-flex align-items-center">
                    <div className="tesey-tools-image-container d-flex hide-on-mobile">
                        <img
                            src={image}
                            className="tesey-tools-image"
                            alt="RND Proton EasyProject services"
                        />
                    </div>
                    <Container>
                        {
                            lang.services.map((service, index) => (
                                <div key={index} className="service-set">
                                    <Row className="justify-content-md-end">
                                        <Col sm={12} lg="6">
                                            <div className="combined-shape"> </div>
                                            <h3 className="service-name">{ service.name }</h3>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-end">
                                        <Col sm={12} lg="6">
                                            <p className="service-text">{ service.text }</p>
                                        </Col>
                                    </Row>
                                </div>

                            ))
                        }
                    </Container>
                </div>
            </div>
        </>
    );
}