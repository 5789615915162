import React from "react";
import "./PricingPage.scss";
import NavProton from "../../shared/NavBar/NavProton";
import ButtonBack from "../../shared/ButtonBack/ButtonBack";
import PricingTableDesktop from "./PricingTableDesktop/PricingTableDesktop";
import EasyProjectLogo from "../../../../assets/images/easy-project-logo.png";
import EasyRedmineLogo from "../../../../assets/images/easy-redmine-logo.png";
import Trial from "../Trial/Trial";
import ServerSolutionSection from "./ServerSolutionSection/ServerSolutionSection";
import MobilePricingSection from "./MobilePricingSection/MobilePricingSection";

export default function PricingPage() {

  return (
    <>
      <NavProton whiteBackground={true} selected="products" className="mb-5" />

      <ButtonBack redirectPath={"/easy_project"} />
      <div className="pricing-page">
        <div className="row w-100">
          <div className="col"></div>
          <div className="col">
            <h1>Pricing</h1>
          </div>
          <div className="col d-flex flex-column align-items-center p-0">
            <img
              className="easy-logos"
              src={EasyProjectLogo}
              alt="easy-project-logo"
            />
            <img
              className="easy-logos"
              src={EasyRedmineLogo}
              alt="easy-redmine-logo"
            />
          </div>
        </div>
        <PricingTableDesktop />
        <MobilePricingSection />
        <ServerSolutionSection />
        <Trial />
      </div>
    </>
  );
}
