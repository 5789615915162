import React, { useState } from "react";
import "./testimonials.scss";
import "./testimonials-animation.scss";
import "../../../styles/shared.scss";
import "../../../styles/shared/page-animations.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import image from "../../../../assets/images/testimonial.jpg";
import { connect } from "react-redux";

function Testimonials({ display, direction, lang }) {
  const [en] = useState({
    title: "R&D",
    text: "One of Proton’s main areas of focus is its research and development wing, which focuses on the development of a variety of solutions for multiple industries. We pride ourselves on our engineering research and development of detection systems used for a diverse range of applications. ",
    author: "Derrick Rogers, Rocket Chat",
  });
  const [ar] = useState({
    title: "البحث والتطوير",
    text: "تركز شركة بروتون أيضًا على البحث والتطوير في التقنيات والحلول للصناعات المتعددة. يتمتع مركز البحث والتطوير لدينا بخبرة في تطوير الأجهزة الإلكترونية الضوئية التي تستخدم على نطاق واسع في أنظمة المراقبة والأمن ومكافحة الطائرات بدون طيار والتحكم الآلي في الوصول. بفضل فريق الباحثين لدينا ، نحن قادرون أيضًا على تركيز جهودنا على تطوير أنظمة معالجة الصور والبيانات بالإضافة إلى النمذجة الرياضية وعمليات محاكاة لاختبار النظم في الوقت الفعلي للأجهزة التي تُستخدم في أنظمة السيارات ومراقبة الحركة الجوية والروبوتات وأنظمة الطاقة.",
    author: "Derrick Rogers, Rocket Chat",
  });

  return (
    <>
      <div
        className={`testimonials-page testimonials-page-scroll-${direction}`}>
        <div className="testimonials-layout align-items-center">
          <Container>
            <Row>
              <Col sm={12} lg={8}>
                <Container>
                  <Row>
                    <Col sm={12} lg={12}>
                      <p className="page-name">
                        {lang === "EN" ? en.title : ar.title}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} lg={10}>
                      <p className="testimonial-text">
                        {lang === "EN" ? en.text : ar.text}
                      </p>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col sm={12} lg={4}>
                <div className="d-flex page-image-container">
                  <img
                    src={image}
                    className="d-inline-block align-top"
                    alt="Testimonials"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Testimonials);
