import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./header.scss";
import "./header-animation.scss";
import Container from "react-bootstrap/Container";
import image from "../../../../assets/images/image - services.jpg";
import ScrollDownButton from "../../shared/ScrollDownButton";
import { connect } from "react-redux";

function Header({ direction, lang }) {
  const [en] = useState({
    text: "Proton provides a comprehensive set of services that can be tailored to fit the clients needs perfectly. Our services can be ordered individually or as a fully featured set, with the client being able to pick and choose based on which services currently suit their needs best.",
  });
  const [ar] = useState({
    text: "توفر شركة بروتون مجموعة شاملة من الخدمات التي يمكن تخصيصها لتناسب احتياجات العملاء تماماً. يمكن طلب خدماتنا بشكل فردي أو كمجموعة كاملة الميزات، بحيث يكون العميل قادراً على الاختيار بناءً على الخدمات التي تناسب احتياجاته حالياً.",
  });

  return (
    <>
      <div className={`services-header services-header-scroll-${direction}`}>
        <div className="header-text-container d-flex align-items-center">
          <Container>
            <Row>
              <Col sm={12} lg="6">
                <h3>{lang === "EN" ? en.text : ar.text}</h3>
              </Col>
            </Row>
          </Container>
        </div>
        <img
          src={image}
          className="services-header-image"
          alt="RND Proton innovation services"
        />
        <ScrollDownButton arrowOnly={true} />
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Header);
