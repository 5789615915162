import React from "react";
import "./ServerSolutionSection.scss";
import vmWareImg from "../../../../../assets/images/VMware_logo.png";
import virtualBoxImg from "../../../../../assets/images/virtualbox-logo.png";
import dockerImg from "../../../../../assets/images/docker-logo.png";
import ServerImage from "../../../../../assets/images/server-img.png";
import ContactFormPopup from "../ContactForm/ContactFormPopup";

export default function ServerSolutionSection() {

  return (
    <div className="server-solution-section">
      <div className="container d-flex align-items-center">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-5">
            <img src={ServerImage} className="img-fluid server-image" alt="Servers image" />
          </div>
          <div className="col-12 col-lg-7">
            <div className="row">
              <h2>Server solution available starting at 595 € </h2>
            </div>
            <div className="row">
              <p>
                Easy Project on your own server? Easy. We understand you might
                need to follow certain{" "}
                <span className="emphasized-text">
                  data protection guidelines
                </span>{" "}
                and policies, and keeping{" "}
                <span className="emphasized-text">
                  sensitive and critical data in-house
                </span>{" "}
                on your own server is a must for you. Keep physical control over
                your own server, all that is possible with Easy Project Server
                Solution.
              </p>
            </div>
            <div className="row pb-4 justify-content-between">
              <div className="col-4">
                <img
                  src={vmWareImg}
                  className="img-fluid"
                  alt="vmWare VirtualBox Docker Logos image"
                />
              </div>
              <div className="col-4">
                <img
                  src={virtualBoxImg}
                  className="img-fluid"
                  alt="vmWare VirtualBox Docker Logos image"
                />
              </div>
              <div className="col-4">
                <img
                  src={dockerImg}
                  className="img-fluid"
                  alt="vmWare VirtualBox Docker Logos image"
                />
              </div>
            </div>
            <div className="row">
              <p>
                Get your server license on{" "}
                <span className="emphasized-text">VMware, VirtualBox,</span> or
                a custom installation package for the easiest Easy Project
                upgrade.
              </p>
            </div>
            <div className="row">
              <ContactFormPopup label="LET'S TALK" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
