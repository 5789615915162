import React from "react";
import "./PriceCard.scss";
import TryItButton from "./TryItButton";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PlatfromPriceCard({ usdPrice }) {
  const includedServices = [
    "Project management",
    "Agile Tools",
    "WBS & Mind Maps",
    "Resource Management",
    "Project Budgets",
    "Expert PM tools",
    "HelpDesk & Ticketing",
    "B2B CRM",
  ];
  return (
    <div className="price-card">
      <div className="container">
        <div className="row flex-column">
          <h3>Platform</h3>{" "}
          {usdPrice ? (
            <h2>
              $23 <span className="cents-price">90</span>
            </h2>
          ) : (
            <h2>
              87 <span className="cents-price">70</span>د.إ
            </h2>
          )}
          <p>user/month</p>
        </div>
        <div className="services-container">
          {includedServices.map((includedService, index) => {
            return (
              <div className="row included-service" key={index}>
                <FontAwesomeIcon icon={faCircleCheck} className="check-icon" />
                <h4>{includedService}</h4>
              </div>
            );
          })}
        </div>
        <TryItButton />
      </div>
    </div>
  );
}
