import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./security.scss";
import "./security-animation.scss";
import image from "../../../../assets/images/image - services3.jpg";
import { connect } from "react-redux";
import ContactFormPopup from "../../EasyProject/PricingPage/ContactForm/ContactFormPopup";

function Security({ direction, lang }) {
  const [en] = useState({
    header: "Software development ",
    services: [
      {
        name: "Ruby on Rails, JavaScript, Swift, React Native, Kotlin",
        text: "",
      },
      {
        name: "Design and develop custom web applications, mobile applications and websites",
        text: "We offer full-stack development services including consulting and hosting services",
      },
      {
        name: "Cross-platform mobile applications",
        text: "We develop, deploy, and manage scalable apps for both Android and IOS devices",
      },
      {
        name: "Dedicated support",
        text: "You can rely on us for resolving any technical issues you may encounter along the way, as well as request trainings for your team",
      },
    ],
    button: "GET STARTED",
  });
  const [ar] = useState({
    header: "تطوير البرمجيات",
    services: [
      {
        name: "C++ و PHP و Ruby و React و Swift و Python",
        text: " يتقن فريق المبرمجين لدينا استخدام هذه اللغات لإنشاء حلول مخصصة تناسب احتياجاتك",
      },
    ],
    button: "البدء",
  });
  const renderServices = () => {
    if (lang === "EN") {
      return en.services.map((service, index) => (
        <Row key={index}>
          <Col sm={12} lg="8">
            <div className="combined-shape"> </div>
            <h3 className="service-name">{service.name}</h3>
          </Col>
          <Col sm={12} lg="7">
            <p className="service-text">{service.text}</p>
          </Col>
        </Row>
      ));
    } else {
      return ar.services.map((service, index) => (
        <Row key={index}>
          <Col sm={12} lg="8">
            <div className="combined-shape"> </div>
            <h3 className="service-name">{service.name}</h3>
          </Col>
          <Col sm={12} lg="7">
            <p className="service-text">{service.text}</p>
          </Col>
        </Row>
      ));
    }
  };

  return (
    <>
      <div className={`services-security services-scroll-${direction}`}>
        <div className="page-layout white-page d-flex justify-content-center">
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12} md={12}>
                <p className="page-name">SERVICES</p>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <h1>{lang === "EN" ? en.header : ar.header}</h1>
              </Col>
            </Row>
            {renderServices()}
            <Row>
              <Col sm={12} lg="8">
                <ContactFormPopup label={lang === "EN" ? en.button : ar.button} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={2}>
            <div className="it-infrastructure-image-container d-flex">
              <img
                  src={image}
                  className="services-services-it-infrastructure-image"
                  alt="RND Proton It Security consulting"
              />
            </div>
          </Col>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Security);
