import React, { useEffect } from "react";
import "./Trial.scss";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

export default function Trial() {
  useEffect(() => {
    const trial = document.querySelector(".trial-section-form");
    const script = document.createElement("script");
    const url = "https://trial-form.easysoftware.com/trial-form.umd.js";

    script.setAttribute("src", url);
    trial.appendChild(script);

    window.easyTrialFormParams = {
      brand: 'ep.com',
      locale: 'en-GB',
      affiliateId: '3p7wqt4v',
      defaultStyle: false,
      translations: {
        "submit": "SUBMIT",
        "yourEmail": "*Email",
        "emailIsRequired": "",
      },
    }

    return () => {
      trial.removeChild(script);
    };
  });

  return (
    <div className="trail-section" id="trial-section">
      <div className="row w-100 flex-column align-items-center justify-content-center trial-section-header">
        <h3>Try Easy Project in a 30-day free trial </h3>
        <p>Full-featured, SSL, protected, Daily Backups, in your Geo</p>
      </div>

      <Container>
        <Row className="justify-content-md-center">
          <Col sm={12} lg={7} className="trial-section-form">
          </Col>
        </Row>
      </Container>
    </div>
  );
}
