export const EasyProjectLang = {
  products: "PRODUCTS",
  title: "Easy Project",
  subTitle: "Easy Project is trusted by over 3,000 organizations worldwide",
  text: "Easy Project is specifically designed for Project & Portfolio managers, Team leaders & Scrum masters, Directors and project Team members, however it can be used by anybody who wants to manage projects in an easy, modern and systematic way.",
  button: "LEARN MORE",
};

export const TeseyLang = {
  products: "PRODUCTS",
  title: "NECTAIN",
  subTitle: "LOW-CODE platform for BPM, PM and DMS",
  text: "Innovative software for business process automation. Fully customizable modular solutions for any type of business. NECTAIN is a combination of BPM, DMS, CRM and PM, able to manage your entire organisation’s digital ecosystem. If you need your processes automized — your projects, documents, or customers managed — NECTAIN is the solution.",
  button: "MORE INFO",
};
