import React, { useState } from "react";
import "./ready-to-start.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import ContactFormPopup from "../../EasyProject/PricingPage/ContactForm/ContactFormPopup";

function ReadyToStart({ lang }) {
  const [en] = useState({
    text: "Are you ready",
    button: "START FREE TRIAL",
  });
  const [ar] = useState({
    text: "هل انت مستعد لتبدء",
    button: "ابدأ الإصدار التجريبي المجاني",
  });
  return (
    <>
      <div className="ready-to-start d-flex align-items-center">
        <Container>
          <Row className="justify-content-md-center">
            <Col sm={12} lg="8">
              <h1>{lang === "EN" ? en.text : ar.text}</h1>
            </Col>
            <Col sm={12} lg="5">
              <h1>{lang === "EN" ? "to start?" : ""}</h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col sm={12} lg="3">
              <ContactFormPopup label="START FREE TRIAL" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(ReadyToStart);
