import React from "react";

export default function TryItButton () {
    return (
      <div className="row">
        {" "}
        <a className="proton-button" href="/easy_project/pricing#trial-section">
          TRY IT
        </a>
      </div>
    );
} 