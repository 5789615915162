import React, { useState } from "react";
import "./body.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import image from "../../../../assets/images/image-about-us.jpg";
import "../../../styles/shared.scss";
import "./body-animation.scss";
import { connect } from "react-redux";

function Body({ direction, lang }) {
  const [en] = useState({
    title: "HISTORY",
    text: "Proton is the culmination of years of work to provide the best possible services in multiple technological fields. And we hope that with our team of experts who have extensive knowledge in their specializations and come from diverse scientific backgrounds we will be to develop technologies and solutions that will make every-day life easier for both our clients and consumers.",
  });
  const [ar] = useState({
    title: "",
    text: "بروتون روتون: هي تتويج لسنوات من العمل لتقديم أفضل الخدمات الممكنة في مجالات تكنولوجية متعددة. ونأمل أن يكون لدينا مع فريق من الخبراء اذو معرفة واسعة في تخصصاتهم،التي تأتي من خلفيات علميه متنوعة أن نعمل على تطوير تقنيات وحلول التي من شأنها أن تجعل الحياة اليومية أسهل لعملائنا وزبائننا.",
  });

  return (
    <>
      <div className={`company-history company-history-scroll-${direction}`}>
        <div className="company-history-layout d-flex align-items-center">
          <Container>
            <Row>
              <Col sm={12} lg={7}>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <p className="page-name">
                        {lang === "EN" ? en.title : ar.title}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <h3>{lang === "EN" ? en.text : ar.text}</h3>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col lg={1}></Col>
              <Col sm={12} lg={4}>
                <div className="d-flex page-image-container">
                  <img
                    src={image}
                    className="d-inline-block align-top about-image"
                    alt="About Proton Innovations"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Body);
