import React from "react";
import "./services.scss";
import ContactFormPopup from "../PricingPage/ContactForm/ContactFormPopup";

export default function Services({ }) {
  const cards = [
    {
      title: "Implementation",
      description:
        "<p class=\"card-text\">- install and deploy Easy Project on your server</p>" +
        "<p class=\"card-text\">- import your current project environment to Easy Project (from 3rd party data migration to custom project settings)</p>" +
        "<p class=\"card-text\">- integrate your Easy Project with 1000+ apps (Salesforce, Zapier, MS Project, Gmail, Outlook, Excel, and many others)</p>" +
        "<p class=\"card-text\">- develop custom Easy Project design that will suit your company branding</p>",
    },
    {
      title: "Consulting",
      description:
        "<p class=\"card-text\">- train your team how to use Easy Project in their daily work (from CEO and Project managers to team members)</p>\n" +
        "<p class=\"card-text\">- our PMI certified project managers will help you optimize your project office and share the best PM practices that will boost your project performance and optimize human resources</p>\n",
    },
    {
      title: "Support",
      description:
        "<p class=\"card-text\">-basic support : contact 24/7, solve a problem within 24 business hours</p>\n" +
        "<p class=\"card-text\">-priority support : contact 24/7, live chats, solve a problem by next business day</p>\n" +
        "<p class=\"card-text\">-server support : installation, upgrading or migrating data, deployment of virtual machine, environment configuration, email configuration and much more</p>",
    },
  ];

  return (
    <>
      <div className="EasyProject-services-section">
        <h2 className="heading">
          Services
        </h2>
        <div className="card-group d-flex justify-content-center">
          <div className="row w-100 row-cols-2 row-cols-lg-3 g-2 px-md-5 py-3">
            {cards.map((card, index) => {
              return (
                <div className="col-4">
                  <div className="service-card">
                    <div className="card-body">
                      <div className="combined-shape"></div>
                      <h5 className="card-title">{card.title}</h5>
                      <div className="card-content" dangerouslySetInnerHTML={{ __html: card.description }}></div>
                      <div className="row justify-content-md-center">
                        <ContactFormPopup label="LET'S TALK" buttonClassName="proton-button button-sm" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

