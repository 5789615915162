import React from "react";
import './ready-to-start-animation.scss'
import SharedReadyToStart from '../../shared/ReadyToStart/ReadyToStart'


export default function ReadyToStart({ direction }) {
    return (
        <>
            <div className={`product-ready-to-start ready-to-start-page-scroll-${direction}`}>
                <SharedReadyToStart />
            </div>
        </>
    );
}