import React, { useState } from "react";
import "./ready-to-start.scss";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
// import './ready-to-start-animation.scss'
// import SharedReadyToStart from '../../shared/ReadyToStart/ReadyToStart'

function ReadyToStart({ lang }) {
  const [en] = useState({
    text: "Do you want to learn more about the EasyProject features ?",
    button: "START FREE TRIAL",
  });
  const [ar] = useState({
    text: "هل انت مستعد لتبدء",
    button: "ابدأ الإصدار التجريبي المجاني",
  });
  return (
    <>
      <div className="ep-ready-to-start d-flex flex-column justify-content-center align-items-center">
        <Row>
          <h2>{lang === "EN" ? en.text : ar.text}</h2>
        </Row>
        <br/>
        <br/>
        <div className="row w-100 justify-content-around align-items-center">
          <a className="proton-button" href="/easy_project#trial-section">
            {lang === "EN" ? en.button : ar.button}
          </a>
          <a
            className="proton-button"
            type="button"
            href="https://www.easyproject.com?a_box=3p7wqt4v"
            target="_blank"
          >
            VISIT WEBSITE
          </a>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(ReadyToStart);
