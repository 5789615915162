import React from "react";
import './slide-show-divider.scss'

export default function SlideShowDivider({currentPageId}) {

    return (
        <>
            <div className={`rnd-divider-container ${[1, 2, 3].includes(currentPageId) ? 'open-divider' : ''}`}>
                <div className={`divider-thin`}>
                    <hr/>
                </div>
                <div className={`divider-gross position-${currentPageId}`}>
                    <hr/>
                </div>
            </div>
        </>
    );
}