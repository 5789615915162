import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./nav-bar-proton.scss";
import logo from "../../../../assets/images/logo.svg";
import logoBlack from "../../../../assets/images/logo-black.svg";
import { useHistory } from "react-router-dom";
import { menuData } from "./navBarData";
import { connect } from "react-redux";
import { changeLang } from "../../../actions";

function NavProton({ selected, whiteBackground, lang, changeLang }) {
  let history = useHistory();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => (e) => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const handleLangChange = () => {
    let div = document.createElement("div");
    document
      .querySelector("body")
      .insertBefore(div, document.querySelector("div"));
    div.style.backgroundColor = "black";
    div.style.width = "2000px";
    div.style.height = "2000px";
    div.style.zIndex = "999";

    location.reload();
    changeLang(lang);
  };

  if (mobileMenuOpen) {
    return (
      <>
        <div className="mobile-open-menu d-flex align-items-center">
          <ul>
            {menuData.map((nav, index) => (
              <li key={index}>
                <a
                  onClick={() => history.push(nav.path)}
                  className={selected === nav.id ? "selected" : ""}>
                  {lang === "EN" ? nav.enLabel : nav.arLabel}
                </a>
              </li>
            ))}

            <li className="close-menu-button">
              <a onClick={toggleMobileMenu()}>CLOSE</a>
            </li>
          </ul>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className={
            whiteBackground ? "white-background-nav" : "back-background-nav"
          }>
          <Navbar>
            <Navbar.Brand onClick={() => history.push("/")}>
              <a href="/">
                <img
                  src={whiteBackground ? logoBlack : logo}
                  width="170"
                  className="d-inline-block align-top"
                  alt="Proton Innovations logo"
                />
              </a>
            </Navbar.Brand>
            <Nav className="mr-auto"> </Nav>
            <Nav className="justify-content-end" activeKey="/home">
              <div className="hide-on-mobile desktop-menu">
                {menuData.map((nav, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      key={index}
                      onClick={() => history.push(nav.path)}
                      className={selected === nav.id ? "selected" : ""}>
                      {lang === "EN" ? nav.enLabel : nav.arLabel}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </div>
              <div className="mobile-menu-button">
                <Nav.Item>
                  <Nav.Link
                    onClick={toggleMobileMenu()}
                    className="mobile-menu-button">
                    MENU
                  </Nav.Link>
                </Nav.Item>
              </div>
              <Nav.Item>
                <span className="menu-divider">|</span>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={handleLangChange} eventKey="link-2">
                  {lang == "EN" ? "AR" : "EN"}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  lang: state.lang,
});
const mapDispatchToProps = (dispatch) => ({
  changeLang: (lang) => dispatch(changeLang(lang)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NavProton);
