import React from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function Item({name, icon, text}) {
    return (
        <>
            <div className="features-item">
                <Row md={12} className="justify-content-md-center">
                    <div>
                        <img src={require(`../../../styles/icons/features/${icon}`)} />
                    </div>
                </Row>
                <Row md={12}>
                    <Col>
                        <h4>{name}</h4>
                    </Col>
                </Row>
                <Row md={12}>
                    <Col>
                        <p>{text}</p>
                    </Col>
                </Row>
            </div>
        </>
    );
}