import React from "react";
import "./tesey.scss";
import "./tesey-animation.scss";
import "../../../styles/shared.scss";
import "../../../styles/shared/page-animations.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ProtonButton from "../../shared/ProtonButton";
import image from "../../../../assets/images/tesey.jpg";
import { TeseyLang as en } from "../../lang/en";
import { TeseyLang as ar } from "../../lang/ar";
import { connect } from "react-redux";

function Tesey({ lang }) {
  return (
    <>
      <div className="tesey-layout d-flex align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col sm={12} lg={7}>
              <Container>
                <Row>
                  <Col sm={12} lg={12}>
                    <p className="page-name">
                      {lang === "EN" ? en.products : ar.products}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={12}>
                    <h1>{lang === "EN" ? en.title : ar.title}</h1>
                  </Col>
                </Row>
                <Row className="space-divider">
                  <Col sm={0} lg={12}>
                    <div style={{ height: 40 }}> </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={9}>
                    <p className="page-subtitle">
                      {lang === "EN" ? en.subTitle : ar.subTitle}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={10}>
                    <p>{lang === "EN" ? en.text : ar.text}</p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={12}>
                    <div className="page-more-button">
                      <ProtonButton
                        label={lang === "EN" ? en.button : ar.button}
                        redirectPath="/tesey"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col sm={12} lg={5}>
              <div className="d-flex page-image-container">
                <img
                  src={image}
                  className="d-inline-block align-top"
                  alt="Tesey"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Tesey);
