import React from "react";
import "../../styles/shared/scroll-down-button.scss";
import { connect } from "react-redux";
import { useState } from "react";

function ScrollDownButton({ arrowOnly, lang }) {
  const [en] = useState({
    text: "SCROLL DOWN",
  });
  const [ar] = useState({
    text: "قم بالتمرير لأسفل",
  });
  const scrollText = () => (lang === "EN" ? en.text : ar.text);
  return (
    <>
      <div className={`scroll-down-container ${arrowOnly ? "arrow-only" : ""}`}>
        <a className="scroll-down-button" type="button">
          {arrowOnly ? "" : scrollText()}
        </a>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(ScrollDownButton);
