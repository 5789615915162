import React, { useState } from "react";
import "./easy-project.scss";
import "./easy-project-animation.scss";
import "../../../styles/shared.scss";
import "../../../styles/shared/page-animations.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ProtonButton from "../../shared/ProtonButton";
import image from "../../../../assets/images/homepage_main_banner.png";
import EasyProjectLogo from "../../../../assets/images/easy-project-logo.svg";
import EasyRedmineLogo from "../../../../assets/images/easy-redmine-logo.svg";
import VideoPopUp from "../../EasyProject/VideoPopUp/VideoPopUp";
import { faCirclePlay } from "../../../../../node_modules/@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "../../../../../node_modules/@fortawesome/react-fontawesome";
import { EasyProjectLang as en } from "../../lang/en";
import { EasyProjectLang as ar } from "../../lang/ar";
import { connect } from "react-redux";

function EasyProject({ direction, lang }) {
  const [videoIsVisible, setVideoIsVisible] = useState(false);

  function playClickHandler(event) {
    event.preventDefault();
    setVideoIsVisible(true);
  }

  function closeClickHandler(event) {
    event.preventDefault();
    setVideoIsVisible(false);
  }

  return (
    <>
      <div
        className={`easy-project-page easy-project-page-scroll-${direction}`}
      >
        <div className="easy-project-layout d-flex align-items-center">
          <Container>
            <p className="page-name">
              {lang === "EN" ? en.products : ar.products}
            </p>
            <Row className="justify-content-between">
              <Col sm={10} lg={7}>
                <h1 className={"ep-heading"}>{lang === "EN" ? en.title : ar.title}</h1>
              </Col>
              <Col sm={10} lg={5}>
                <Row className="justify-content-start">
                  <img
                    className="easy-logos mr-5 mt-4"
                    src={EasyProjectLogo}
                    alt="easy-project-logo"
                    width={180}
                  />
                  <img
                    className="easy-logos mr-5 mt-4"
                    src={EasyRedmineLogo}
                    alt="easy-redmine-logo"
                    width={180}
                  />
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center">
              <Col sm={12} lg={7}>
                <Row>
                  <Col sm={12} lg="10">
                    <p className="page-subtitle">
                      {lang === "EN" ? en.subTitle : ar.subTitle}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg="10">
                    <p>{lang === "EN" ? en.text : ar.text}</p>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} lg={5}>
                <div className="video-preview-container">
                  <img
                    className="video-preview-img img-fluid"
                    src={image}
                    art="Laptop with Easy Project"
                  />
                  <FontAwesomeIcon
                    className="play-icon"
                    icon={faCirclePlay}
                    onClick={playClickHandler}
                  />
                </div>
                {videoIsVisible && (
                  <VideoPopUp closeClickHandler={closeClickHandler} />
                )}
              </Col>
            </Row>
            <Row className="justify-content-md-around align-items-md-center w-100 pt-3">
              <div className="page-more-button">
                <ProtonButton
                  label={lang === "EN" ? en.button : ar.button}
                  redirectPath="/easy_project"
                />
              </div>
              <div className="page-more-button">
                <a className="proton-button" href="/easy_project#trial-section">
                  {lang === "EN" ? "TRY IT FREE" : "ar.button"}
                </a>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(EasyProject);
