import React, { useState, useEffect } from "react";
import "./show-case-proton.scss";
import "./show-case-proton-animations.scss";
import "./show-case-proton-animations-reversed.scss";
import NavProton from "../../shared/NavBar/NavProton";
import protonVideo from "../../../styles/video/proton-hero-video.mp4";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ProtonButton from "../../shared/ProtonButton";
import ScrollDownButton from "../../shared/ScrollDownButton";
import { connect } from "react-redux";

function Main({ display, direction, lang }) {
  const [en] = useState({
    titleMain:
      "“Any sufficiently advanced technology is indistinguishable from magic.”",
    subTitle: "Arthur C. Clarke",
  });
  const [ar] = useState({
    titleMain: "أي تقنية متقدمة بما فيه الكفاية لا يمكن تمييزها عن السحر ",
    subTitle: " أرثور س كلارك",
  });
  useEffect(() => {
    let p = [].slice.call(document.getElementsByTagName("p"));
    let h1 = [].slice.call(document.getElementsByTagName("h1"));
    if (lang === "AR") {
      document.body.style.direction = "rtl";
      document.getElementById("video-wrap").style.width = "0px";
      document.getElementsByClassName("videoTag")[0].style.transform =
        "rotateY(180deg)";
      document.getElementById("main-text-container").style.position =
        "relative";
      document.getElementById("main-text-container").style.left = "-200px";
      h1.forEach((element) => (element.style.textAlign = "right"));
      p.forEach((element) => (element.style.textAlign = "right"));
    } else {
      document.body.style.direction = "ltr";
      document.getElementsByClassName("videoTag")[0].style.transform =
        "rotateY(0deg)";
      document.getElementById("main-text-container").style.left = "0px";

      h1.forEach((element) => (element.style.textAlign = "left"));
      p.forEach((element) => (element.style.textAlign = "left"));
    }
  });

  return (
    <>
      <div
        className={`show-case-page ${rootClassName()}${
          lang === "AR" ? "-reversed" : ""
        }`}>
        <div className="side-bar-container d-flex align-items-center">
          <div className="back-background-block"> </div>
          <Container id="main-text-container">
            <Row>
              <Col sm={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 4 }}>
                <h1 className="title-main-home">
                  {lang === "EN" ? en.titleMain : ar.titleMain}
                </h1>
              </Col>
              <Col sm={{ span: 9, offset: 0 }} lg={{ span: 9, offset: 4 }}>
                <p className="sub-title-main-home">
                  {lang === "EN" ? en.subTitle : ar.subTitle}
                </p>
              </Col>
              <Col
                sm={{ span: 9, offset: 0 }}
                lg={{ span: 8, offset: 4 }}
                className="main-page-proton-button">
                <ProtonButton
                  label={lang === "EN" ? "LEARN MORE" : "تعرف على المزيد"}
                  redirectPath="/company"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="video-wrapper" id="video-wrap">
          <video className="videoTag" autoPlay loop muted>
            <source src={protonVideo} type="video/mp4" />
          </video>
        </div>
        <div className="showcase">
          <NavProton />
          <ScrollDownButton />
        </div>
      </div>
    </>
  );

  function rootClassName() {
    if (direction === "none" && display) {
      return "page-show-case-initialize";
    } else {
      return `page-scroll-${direction}`;
    }
  }
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Main);
