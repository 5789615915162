import React from "react";
import "./EPManagementCard.scss";
import ProjectManagerIcon from "../../../../../assets/images/ManagementIcons/Project Manager.png";
import AgileIcon from "../../../../../assets/images/ManagementIcons/Agile.png";
import ResumeIcon from "../../../../../assets/images/ManagementIcons/Resume.png";
import TrackingIcon from "../../../../../assets/images/ManagementIcons/Tracking.png";
import WorkflowIcon from "../../../../../assets/images/ManagementIcons/Workflow.png";
import ProjectIcon from "../../../../../assets/images/ManagementIcons/Project.png";
import TemplateIcon from  "../../../../../assets/images/ManagementIcons/Template.png";
import DashboardIcon from "../../../../../assets/images/ManagementIcons/Dashboard.png";

export default function EPMnagementCard({ title, description }) {
  function IconSource(title) {
    if (title === "Project Management") {
      return ProjectManagerIcon;
    } else if (title === "Portfolio Management") {
      return ResumeIcon;
    } else if (title === "Agile - Kanban & Scrum") {
      return AgileIcon;
    } else if (title === "Time Tracking") {
      return TrackingIcon;
    } else if (title === "Workflow Management") {
      return WorkflowIcon;
    } else if (title === "Project Controlling & Reporting") {
      return ProjectIcon;
    } else if (title === "Project Templates") {
      return TemplateIcon;
    } else {
      return DashboardIcon;
    }
  }
  return (
    <div className="col">
      <div className="card">
        <img
          src={IconSource(title)}
          className="card-img-top img-fluid"
          alt={`${title} image`}
        />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
        </div>
      </div>
    </div>
  );
}
