import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./contact-us.scss";
import "./contact-us-animation.scss";
import Container from "react-bootstrap/Container";
import ContactForm from "../../shared/ContactForm/ContactForm";
import { connect } from "react-redux";

function ContactUs({ direction, lang }) {
  return (
    <>
      <div className={`contact-us-page contact-scroll-${direction}`}>
        <div className="white-page contact-us-form d-flex align-items-center">

          <Container>
            <Row className="justify-content-md-center">
              <Col sm={12} lg={7}>
                <h1>{lang === "EN" ? "Contact us" : "اتصل بنا"}</h1>
                <ContactForm
                  oneColumn={true}
                  useTopic={true}
                  whiteBackground={true}
                />
              </Col>
            </Row>
          </Container>

        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(ContactUs);
