import React, { useState } from "react";
import './features.scss'
import './features-animation.scss'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import image from "../../../../assets/images/line.png";

export default function Features({direction}) {
    const [lang] = useState({
        firstSet: [
            'Low code platform for process definition',
            'Automation of processes through workflows',
            'Easy integrations',
            'Ready-made processes for quick and easy customer adaptation',
            'Easy customizable'
        ],
        secondSet: [
            'Integrated functions',
            'The process of configuring RBAC',
            'Provides maximum flexibility',
            'Allows you to expand the functionality'
        ]
    });

    return (
        <>
            <div className={ `tesey-features tesey-scroll-${direction}` }>
                <div className="page-layout white-page d-flex align-items-center">
                    <Container>
                        <Row className="justify-content-md-center">
                            <p className="page-name">SOURCE VIDEO</p>
                        </Row>
                        <Row className="justify-content-md-center tesey-feature-main-container">
                            <Col xs lg="5" className="left-feature-column">
                                {
                                    lang.firstSet.map((feature, index) => (
                                        <Row className="justify-content-md-center" key={index}>
                                            <Col xs lg="12">
                                                <h4>{feature}</h4>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </Col>
                            <Col lg={1} className="d-flex justify-content-md-center tesey-feature-line-img-col hide-on-mobile">
                                <img
                                    src={image}
                                    className="services-services-it-infrastructure-image"
                                    alt="RND Proton It Infrastructure"
                                />
                            </Col>
                            <Col lg={1} >

                            </Col>
                            <Col xs lg="5" className="right-feature-column">
                                {
                                    lang.secondSet.map((feature, index) => (
                                        <Row className="justify-content-md-center" key={index}>
                                            <Col xs lg="12" class='align-self-center'>
                                                <h4>{feature}</h4>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}
