import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./header.scss";
import "./header-animation.scss";
import "./header-animation-reversed.scss";
import Container from "react-bootstrap/Container";
import ScrollDownButton from "../../shared/ScrollDownButton";
import { connect } from "react-redux";

function Header({ direction, lang }) {
  const [en] = useState({
    heading:
      "One of Proton’s main areas of focus is its research and development wing, which focuses on the development of a variety of solutions for multiple industries. We pride ourselves on our engineering research and development of detection systems used for various applications.",
    subHeading:
      "Furthermore, our R&D wing has experience with developing optoelectronic devices which are widely used in telecommunications or automatic access control systems. We have also applied our extensive experience in the development of laser systems.",
  });
  const [ar] = useState({
    heading:
      "تهتم بروتون بالبحث والتطوير المستمر، والذي يركز على تطوير مجموعة متنوعة من الحلول للصناعات المتعددة. نحن نفخر بأنفسنا لبحوثنا الهندسية وتطوير أنظمة الكشف المستخدمة لمجموعة متنوعة من التطبيقات.",
    subHeading:
      "ذلك ، يتمتع مركز البحث والتطوير لدينا بخبرة في تطوير الأجهزة الإلكترونية الضوئية التي تُستخدم على نطاق واسع في أنظمة المراقبة والأمن ومكافحة الطائرات بدون طيار والتحكم الآلي في الوصول. لقد طبقنا أيضًا خبرتنا الواسعة في تطوير أنظمة الليزر.",
  });
  useEffect(() => {
    let p = [].slice.call(document.getElementsByTagName("p"));
    let h1 = [].slice.call(document.getElementsByTagName("h1"));
    let h2 = [].slice.call(document.getElementsByTagName("h2"));
    let h3 = [].slice.call(document.getElementsByTagName("h3"));
    let black_bar = document.querySelector(".left-black-bar");
    if (lang === "AR") {
      document.body.style.direction = "rtl";
      h1.forEach((element) => (element.style.textAlign = "right"));
      h2.forEach((element) => (element.style.textAlign = "right"));
      h3.forEach((element) => (element.style.textAlign = "right"));
      p.forEach((element) => (element.style.textAlign = "right"));
      // black_bar.style.opacity = 0;
    } else {
      document.body.style.direction = "ltr";
      h1.forEach((element) => (element.style.textAlign = "left"));
      h2.forEach((element) => (element.style.textAlign = "left"));
      h3.forEach((element) => (element.style.textAlign = "left"));
      p.forEach((element) => (element.style.textAlign = "left"));
      // black_bar.style.opacity = 1;
    }
  });

  return (
    <>
      <div
        className={`rnd-header rnd-header-scroll-${direction}${
          lang === "AR" ? "-reversed" : ""
        }`}>
        <div className="rnd-green-left-bar"> </div>
        <div className="rnd-header-layout d-flex align-items-center">
          <Container>
            <Row className="justify-content-md-center">
              <Col sm="10" lg="8">
                <h3 className="company-header-text">
                  {lang === "EN" ? en.heading : ar.heading}
                </h3>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col sm="10" lg="8">
                <p className="company-header-subtitle">
                  {lang === "EN" ? en.subHeading : ar.subHeading}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <ScrollDownButton arrowOnly={true} />
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Header);
