export default function langReducer(
  state = { lang: localStorage.lang ? localStorage.lang : "EN" },
  action
) {
  switch (action.type) {
    case "EN_LANG":
      return { ...state, lang: "EN" };
    case "AR_LANG":
      return { ...state, lang: "AR" };
    default:
      return state;
  }
}
