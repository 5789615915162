import React from "react";
import "./button-back.scss";
import {useHistory} from "react-router-dom";

export default function ButtonBack({ redirectPath }) {
    let history = useHistory();

    return (
        <>
            <a className="proton-button-back hide-on-mobile"
               type="button"
               onClick={() => history.push(redirectPath)}
            >
               <span>BACK</span>
            </a>
        </>
    );
}