import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./slide-two.scss";
import "./slide-two-animation.scss";
import Container from "react-bootstrap/Container";
import image from "../../../../assets/images/image-rnd2.jpg";
import { connect } from "react-redux";

function SlideTwo({ direction, lang }) {
  const [en] = useState({
    text: "Proton can provide scalable solutions for projects with varying degrees of complexity, that involve both classic and modern methods of processing. ",
  });
  const [ar] = useState({
    text: "يمكن أن توفر بروتون حلولًا قابلة لتطوير المشاريع بدرجات متفاوتة من التعقيد ، والتي تتضمن طرق المعالجة الكلاسيكية والحديثة.",
  });

  return (
    <>
      <div className={`rnd-slide-two rnd-header-scroll-${direction}`}>
        <div className="rnd-slide-two-layout d-flex align-items-center">
          <img
            src={image}
            className="background-rnd-scalable-image"
            alt="RND Proton scalable solutions"
          />
          <Container>
            <Row className="">
              <Col md="9">
                <h2>{lang === "EN" ? en.text : ar.text}</h2>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(SlideTwo);
