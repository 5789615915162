import React from "react";
import "../../styles/shared/page-divider.scss";

export default function PageDivider({ direction }) {
    return (
        <>
            <div className={`page-divider-container open-divider`}>
                <div className={`divider-thin`}>
                    <hr/>
                </div>
                <div className={`divider-gross position-${direction === 'right' ? 'right' : 'left'}`}>
                    <hr/>
                </div>
            </div>
        </>
    );
}
