import React from "react";
import "./TableBodyRow.scss"
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InfoIcon from "../../../../../../assets/images/info-icon.png";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TableBodyRow({
  rowTitle,
  essentialsColumnCheck,
  businessColumnCheck,
  platformColumnCheck,
  firstRow,
  tooltip,
}) {
  const renderTooltip = (props) => (
    <Tooltip className="info-tooltip" id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  );

  return (
    <>
      <tr>
        <td scope="row">
          <div className="row-label px-2 d-flex justify-content-between">
            <div>{rowTitle} </div>
            <div>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <img
                  src={InfoIcon}
                  alt="Info sign icon grey"
                  className="info-icon img-fluid"
                />
                {/* <FontAwesomeIcon icon={faCircleInfo} className="info-icon fa-light" /> */}
              </OverlayTrigger>
            </div>
          </div>
        </td>
        <td className="essentials-column">
          {essentialsColumnCheck && (
            <FontAwesomeIcon icon={faCircleCheck} className="check-icon" />
          )}
        </td>
        <td className="business-column">
          {businessColumnCheck && (
            <FontAwesomeIcon icon={faCircleCheck} className="check-icon" />
          )}
        </td>
        <td>
          {platformColumnCheck && (
            <FontAwesomeIcon icon={faCircleCheck} className="check-icon" />
          )}
        </td>
        {firstRow && (
          <td rowSpan={7} className="enterprise-column">
            <div className="row py-2">
              <div className="col-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="enterprise-column-check-icon"
                />
              </div>
              <div className="col">
                Custom SLA and dedicated customer success manager
              </div>
            </div>
            <div className="row py-2">
              <div className="col-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="enterprise-column-check-icon"
                />
              </div>
              <div className="col">Integration services</div>
            </div>
            <div className="row py-2">
              <div className="col-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="enterprise-column-check-icon"
                />
              </div>
              <div className="col">Software modifications</div>
            </div>
            <div className="row py-2">
              <div className="col-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="enterprise-column-check-icon"
                />
              </div>
              <div className="col"> High availability cloud solutions</div>
            </div>
          </td>
        )}
      </tr>
    </>
  );
}
