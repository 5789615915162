import React from "react";
import "./Integrations.scss";
import ZapierLogo from "../../../../assets/images/IntegrationCompaniesLogo/zapier.png";
import OutlookLogo from "../../../../assets/images/IntegrationCompaniesLogo/outlook.png";
import MS_ProjectLogo from "../../../../assets/images/IntegrationCompaniesLogo/ms_project.png";
import JiraLogo from "../../../../assets/images/IntegrationCompaniesLogo/jira.png";
import ExcelLogo from "../../../../assets/images/IntegrationCompaniesLogo/excel.png";
import OgilvyLogo from "../../../../assets/images/IntegrationCompaniesLogo/ogilvy.png";
import ContinentalLogo from "../../../../assets/images/IntegrationCompaniesLogo/continental.png";
import BoschLogo from "../../../../assets/images/IntegrationCompaniesLogo/bosch.png";
import LearLogo from "../../../../assets/images/IntegrationCompaniesLogo/lear.png";
import ToyotaLogo from "../../../../assets/images/IntegrationCompaniesLogo/toyota.png";
import IPMALogo from "../../../../assets/images/IntegrationCompaniesLogo/IPMA.png";

export default function Integrations() {
  return (
    <div className="integrations-section justify-content-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>Easy Project integrations</h3>
          </div>
          <div className="col-12">
            <p>
              Easy Project is integrated with other software for your easy
              on-boarding. Integrated with 1,000+ apps using Zapier. Learn more
              in our Easy Project Integrity Handbook.
            </p>
          </div>
        </div>
        <div className="row align-items-center pt-3 pb-5">
          <div className="col-4 col-lg-2">
            <img src={ZapierLogo} className="img-fluid" alt="Zapier logo" />
          </div>
          <div className="col-4 col-lg-2">
            <img src={OutlookLogo} className="img-fluid" alt="Outlook logo" />
          </div>
          <div className="col-4 col-lg-2">
            <img
              src={MS_ProjectLogo}
              className="img-fluid"
              alt="MS_Project logo"
            />
          </div>
          <div className="col-4 col-lg-2">
            <img src={JiraLogo} className="img-fluid" alt="Jira logo" />
          </div>
          <div className="col-4 col-lg-2">
            <img src={ExcelLogo} className="img-fluid" alt="Excel logo" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h4>Trusted by top companies worldwide</h4>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-4 col-lg-2">
            <img
              src={OgilvyLogo}
              className="img-fluid pt-4"
              alt="Oglivy logo"
            />
          </div>
          <div className="col-4 col-lg-2">
            <img
              src={ContinentalLogo}
              className="img-fluid"
              alt="Continental logo"
            />
          </div>
          <div className="col-4 col-lg-2">
            <img src={BoschLogo} className="img-fluid" alt="Bosch logo" />
          </div>
          <div className="col-4 col-lg-2">
            <img src={LearLogo} className="img-fluid" alt="Lear logo" />
          </div>
          <div className="col-4 col-lg-2">
            <img src={ToyotaLogo} className="img-fluid" alt="Toyota logo" />
          </div>
        </div>
        <div className="row py-3">
          <div className="col-12">
            <h4>Project management compatible with IPMA</h4>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col">
            <img
              src={IPMALogo}
              className="img-fluid"
              alt="International project management association logo"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              Easy Project is under the supervision of SHINE Consulting Ltd. -
              holder of accreditation of the Project Management Training Program
              “Project Management in Practice” (International Standard IPMA)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
