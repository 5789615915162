export const menuData = [
  {
    enLabel: "COMPANY",
    arLabel: "الشركة",
    path: "/company",
    id: "company",
  },
  {
    enLabel: "PRODUCTS",
    arLabel: "منتجات",
    path: "/products",
    id: "products",
  },
  {
    enLabel: "R&D",
    arLabel: "البحث والتطوير",
    path: "/rnd",
    id: "rnd",
  },
  {
    enLabel: "SERVICES",
    arLabel: "الخدمات",
    path: "/services",
    id: "services",
  },
  {
    enLabel: "CONTACT",
    arLabel: "جهات الاتصال",
    path: "/contact",
    id: "contact",
  },
];
