import React from "react";
import './easy-project.scss'
import EasyProjectV2 from '../../landing/EasyProject/EasyProjectV2'

export default function Header({direction}) {
    return (
        <>
            <div className="product-easy-project">
                <EasyProjectV2 direction={direction} />
            </div>
        </>
    );
}
