import React, {useState} from "react";
import "./tools.scss";
// import "./tools-animation.scss"
import EPMnagementCard from "./EPManagementCard/EPManagementsCard";
import ContactFormPopup from "../PricingPage/ContactForm/ContactFormPopup";

export default function EasyProgectManagementAreasSection({ language }) {
  const cards = [
    {
      title: "Project Management",
      description:
        "WBS • Gantt Chart • Earned Value • Baselines • Quick Project Planner",
    },
    {
      title: "Portfolio Management",
      description: "Portfolio Statements • Portfolio Gantt • Portfolio Trees",
    },
    {
      title: "Agile - Kanban & Scrum",
      description: "Kanban Board • Scrum • Burndown Chart  • Backlogs",
    },
    {
      title: "Time Tracking",
      description: "Time tracking and reporting • Timesheets • Stopwatch",
    },
    {
      title: "Workflow Management",
      description:
        "Task Tracking • Time Tracking • Workflows • Checklists • Calendars",
    },
    {
      title: "Project Controlling & Reporting",
      description:
        "Reporting tools - graphs & charts • Earned Value Management • Burndown Chart",
    },
    {
      title: "Project Templates",
      description:
        "Production • Strategic Project • Product Development • HR Project • Back Office",
    },
    {
      title: "Personal Dashboards",
      description:
        "Project Manager • Project Team Member • Team Leader • Portfolio Manager • Scrum Master • CEO",
    },
  ];

  return (
    <>
      <div className="EasyProject-management-section">
        <div className="header-border">
          <h2 className="heading">
            <span>Easy Project covers these management areas</span>
          </h2>
          <div className="card-group d-flex justify-content-center">
            <div className="row w-100 row-cols-2 row-cols-lg-4 g-2 px-md-5 py-3">
              {cards.map((card, index) => {
                return (
                  <EPMnagementCard
                    key={index}
                    title={card.title}
                    description={card.description}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className="learn-more-section">
          <h3>
            Do you want to learn more about the EasyProject features <br />
            Our team will be happy to guide you through Easy Project in a live meeting
          </h3>
          <div className="row w-100 justify-content-around align-items-center">
            <ContactFormPopup label="BOOK A DEMO" />
          </div>
        </div>
      </div>
    </>
  );
}
