import React from "react";
import { FontAwesomeIcon } from "../../../../../node_modules/@fortawesome/react-fontawesome";
import { faXmark } from "../../../../../node_modules/@fortawesome/free-solid-svg-icons";
import "./VideoPopUp.scss";

export default function VideoPopUp({ closeClickHandler }) {
  let videoSourceUrl = "https://www.youtube.com/embed/DAXnOmWGovE?autoplay=1";

  return (
    <div
      className="video-popUp-container video-popUp-full-dimension"
      onClick={closeClickHandler}
    >
      <div className="video-popUp-nav">
        <div className="video-popUp-toolbar">
          <div className="video-popUp-toolbar-button video-popUp-flex-centered">
            <FontAwesomeIcon
              className="btn-close"
              icon={faXmark}
              onClick={closeClickHandler}
            />
          </div>
        </div>
      </div>
      <div className="video-popUp-full-dimension video-popUp-absoluted video-popUp-flex-centered">
        <iframe
          className="video-source"
          src={videoSourceUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
