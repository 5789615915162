import React, {useEffect, useState} from "react";
import NavProton from "../shared/NavBar/NavProton";
import Header from './Header/Header'
import SlideOne from './SlideOne/SlideOne'
import SlideTwo from './SlideTwo/SlideTwo'
import SlideThree from './SlideThree/SlideThree'
import ReadyToStart from './ReadyToStart/ReadyToStart'
import Footer from './Footer/Footer'
import './rnd.scss'

export default function Rnd() {

    return (
        <>
            <div className={`proton-rnd-page`}>
                <NavProton selected="rnd"/>

                <Header />
                <SlideOne  />
                <SlideTwo  />
                <SlideThree />
                <ReadyToStart  />
                <Footer  />
            </div>
        </>
    );
}
