import React, { useState, useEffect } from "react";
import "./header.scss";
// import './header-animation.scss'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import image from "../../../../assets/images/Laptop-with-easyProject.png";
import EasyProjectLogo from "../../../../assets/images/easy-project-logo.svg";
import EasyRedmineLogo from "../../../../assets/images/easy-redmine-logo.svg";
import VideoPopUp from "../VideoPopUp/VideoPopUp";
import { faCirclePlay } from "../../../../../node_modules/@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "../../../../../node_modules/@fortawesome/react-fontawesome";
import ButtonBack from "../../shared/ButtonBack/ButtonBack";

export default function Header({ language }) {
  const [lang] = useState({
    heading: "Easy Project",
    subHeader: "The best project management tools in one application",
    text: "Easy Project 11+ is based on WBS, Gantt, SCRUM, Kanban and other best PM practices. It is extensible with modules for Resources, Finances, Help Desk, Business Intelligence, and DevOps toolset.",
  });

  const [videoIsVisible, setVideoIsVisible] = useState(false);

  function playClickHandler(event) {
    event.preventDefault();
    setVideoIsVisible(true);
  }

  function closeClickHandler(event) {
    event.preventDefault();
    setVideoIsVisible(false);
  }

  return (
    <>
      <ButtonBack redirectPath={"/products"} />
      <div className="ep-header-container d-flex align-items-center">
        <Container>
          <Row className="justify-content-between">
            <Col sm={10} lg={7}>
              <h1 className={"ep-heading"}>{lang.heading}</h1>
            </Col>
            <Col sm={10} lg={5}>
              <Row className="justify-content-start">
                <img
                  className="easy-logos mr-5 mt-4"
                  src={EasyProjectLogo}
                  alt="easy-project-logo"
                  width={180}
                />
                <img
                  className="easy-logos mr-5 mt-4"
                  src={EasyRedmineLogo}
                  alt="easy-redmine-logo"
                  width={180}
                />
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col sm={12} lg={7}>
              <Row>
                <Col sm={12} lg="10">
                  <h4 className="pt-5 subheader">{lang.subHeader}</h4>
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg="10">
                  <p className="text">{lang.text}</p>
                </Col>
              </Row>
            </Col>
            <Col sm={12} lg={5}>
              <div className="video-preview-container">
                <img
                  className="video-preview-img img-fluid"
                  src={image}
                  art="Laptop with Easy Project"
                />
                <FontAwesomeIcon
                  className="play-icon"
                  icon={faCirclePlay}
                  onClick={playClickHandler}
                />
              </div>
              {videoIsVisible && (
                <VideoPopUp closeClickHandler={closeClickHandler} />
              )}
            </Col>
          </Row>
          <Row className="justify-content-around align-items-center pt-5">
            <a className="proton-button" href="/easy_project#trial-section">
              {language === "EN" ? "START FREE TRIAL" : "ar.button"}
            </a>
            <a
              className="proton-button ms-5"
              href="/easy_project/pricing"
            >
              {language === "EN" ? "PRICING" : "ar.button"}
            </a>
          </Row>
        </Container>
      </div>
    </>
  );
}
