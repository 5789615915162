import React from "react";
import './footer-animation.scss'
import Footer from '../../shared/Footer/Footer'

export default function ProductsFooter({direction}) {
    return (
        <>
            <div className={ `contact-footer contact-scroll-${direction}` }>
                <Footer />
            </div>
        </>
    );
}