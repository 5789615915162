import React, { useEffect } from "react";
import "./footer-animation.scss";
import Footer from "../../shared/Footer/Footer";

export default function LandingFooter({ direction }) {
  return (
    <>
      <div className={`footer-page footer-page-scroll-${direction}`}>
        <Footer />
      </div>
    </>
  );
}
