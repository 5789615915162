// api_key= AIzaSyCGmBoQkp5OwIKkXI1EJ_H3p4y0ignd8bk

import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import MapStyles from './map-style.json'

const containerStyle = {
    width: '600px',
    height: '600px',
    zIndex: 10
};

const center = {
    lat: 24.502578,
    lng: 54.389608
};

function ProtonGoogleMap() {
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyCGmBoQkp5OwIKkXI1EJ_H3p4y0ignd8bk"
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={14}
                options={{
                    styles: MapStyles,
                    disableDefaultUI: true
                }}
                className="proton-map-container"
            >
                { /* Child components, such as markers, info windows, etc. */ }
                <></>
            </GoogleMap>
        </LoadScript>
    )
}

export default React.memo(ProtonGoogleMap)