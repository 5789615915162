import React, { useState, useEffect } from "react";
import "./MobilePricingSection.scss";
import EssencialsPriceCard from "./EssencialsPriceCard";
import BusinessPriceCard from "./BusinessPriceCard";
import PlatformPriceCard from "./PlatformPricingCard";

export default function MobilePricingSection() {
  //   const [essentialsCard, seteEssentialsCard] = useState(false);
  //   const [businessCard, setBusinessCard] = useState(true);
  //   const [platformCard, setPlatformCard] = useState(false);
  const tarifs = [
    { id: "essentials", name: "Essentials" },
    { id: "business", name: "Business" },
    { id: "platform", name: "Platform" },
  ];
  const [currentTarif, setcurrentTarif] = useState("business");
  const [usdPrice, setUsdPrice] = useState(true);

  //   useEffect(
  //     function getCard() {
  //       if (currentTarif === "essentials") {
  //         setCard(<div className="tarif-card">"Essentials card"</div>);
  //       } else if (currentTarif === "business") {
  //         setCard(<div className="tarif-card">"Business card"</div>);
  //       } else {
  //         setCard(<div className="tarif-card">"Platform card"</div>);
  //       }
  //     },
  //     [currentTarif]
  //   );

  function clickHandler(event) {
    setcurrentTarif(event.target.id);
  }

  function currensySelectHadler() {
    setUsdPrice(!usdPrice);
  }
  return (
    <div className="mobile-pricing-section">
      <div className="row">
        <select
          className="form-select currency-select"
          aria-label="Currency select"
          onChange={currensySelectHadler}
        >
          <option value="1">USD ($)</option>
          <option value="2">AED (د.إ)</option>
        </select>
      </div>
      <div className="row mt-5">
        {tarifs.map((tarif, index) => {
          return (
            <div key={index} className="col">
              <button
                id={tarif.id}
                className={`${currentTarif === tarif.id ? "active" : ""}`}
                onClick={clickHandler}
              >
                {tarif.name}
              </button>
            </div>
          );
        })}
      </div>
      <div className="row">
        {currentTarif === "essentials" ? (
          <EssencialsPriceCard usdPrice={usdPrice} />
        ) : (
          ""
        )}
        {currentTarif === "business" ? (
          <BusinessPriceCard usdPrice={usdPrice} />
        ) : (
          ""
        )}
        {currentTarif === "platform" ? (
          <PlatformPriceCard usdPrice={usdPrice} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
