import React, { useState } from "react";

export default function TableHeadRow() {
  const [usdPrice, setUsdPrice] = useState(true);

  function currensySelectHadler() {
    setUsdPrice(!usdPrice);
  }
  return (
    <>
      <tr>
        <th colSpan="2"></th>
        <th scope="col" className="most-popular-column py-3">
          Most Popular
        </th>
        <th scope="col"></th>
        <th scope="col" className="currency-select-cell align-items-center">
          <select
            className="form-select currency-select"
            aria-label="Currency select"
            onChange={currensySelectHadler}
          >
            <option value="1">USD ($)</option>
            <option value="2">AED (د.إ)</option>
          </select>
        </th>
      </tr>
      <tr>
        <th scope="col"></th>
        <td scope="col" className="essentials-column">
          <h3>Essentials</h3>{" "}
          {usdPrice ? (
            <h2>
              $5 <span className="cents-price">90</span>
            </h2>
          ) : (
            <h2>
              21 <span className="cents-price">65</span>د.إ 
            </h2>
          )}
          <p>user/month</p>
        </td>
        <td scope="col" className="business-column">
          <h3>Business</h3>{" "}
          {usdPrice ? (
            <h2>
              $11 <span className="cents-price">90</span>
            </h2>
          ) : (
            <h2>
              43 <span className="cents-price">67</span>د.إ
            </h2>
          )}
          <p>user/month</p>
        </td>
        <td scope="col">
          <h3>Platform</h3>{" "}
          {usdPrice ? (
            <h2>
              $23 <span className="cents-price">90</span>
            </h2>
          ) : (
            <h2>
              87 <span className="cents-price">70</span>د.إ
            </h2>
          )}
          <p>user/month</p>
        </td>
        <td scope="col" className="enterprise-column-header">
          <h3>Enterprise</h3>
        </td>
      </tr>
    </>
  );
}
