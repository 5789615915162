import React, {useEffect, useState} from "react";
import NavProton from "../shared/NavBar/NavProton";
import './tesey.scss'
import Header from './Header/Header'
import Tools from './Tools/Tools'
import Features from './Features/Features'
import ReadyToStart from './ReadyToStart/ReadyToStart'
import EpFooter from './Footer/Footer'

import '../../styles/shared.scss'

export default function Tesey() {
    const [pageIndex, setPageIndex] = useState(0);
    const [direction, setDirection] = useState('right');
    const [waitingAnimation, setWaitingAnimation] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setWaitingAnimation(false)
        }, 1000);
    }, []);

    return (
        <>
            <div className={`proton-services-page ${sidebarClass()}`}
                 onWheel = {(e) => !waitingAnimation && wheelToPage(e)}>
                <NavProton whiteBackground={true} selected="products"/>

                <Header direction={getDirection(0)} />
                <Tools direction={getDirection(1)} />
                <Features direction={getDirection(2)} />
                <ReadyToStart direction={getDirection(3)} />
                <EpFooter direction={getDirection(4)} />
            </div>
        </>
    );

    function getDirection(id) {
        if (pageIndex === id) {
            return direction
        } else if (pageIndex > id) {
            return 'right-hide'
        } else {
            return 'left-hide'
        }
    }

    function sidebarClass() {
        if (pageIndex === 0) {
            return ''
        } else {
            return 'navbar-hidden'
        }
    }

    function wheelToPage(e) {
        setWaitingAnimation(true)
        let currentPage

        if (e.deltaY < 0) {
            setDirection('left')
            currentPage = pageIndex - 1
            setPageIndex(pageIndex === 0 ? 0 : currentPage)
        } else if (e.deltaY > 0) {
            setDirection('right')
            currentPage = pageIndex + 1
            setPageIndex(pageIndex === 4 ? 4 : currentPage)
        }

        setTimeout(() => {
            setWaitingAnimation(false)
        }, 1000);
    }
}