import React from "react";
import Header from './Header/Header'
import Body from './Body/Body'
import NavProton from '../shared/NavBar/NavProton'
import Footer from './Footer/Footer'
import "./company.scss";
import "./company-animation.scss";

export default function Company() {
    return (
        <>
            <div className={`proton-company-page `}>
                <NavProton selected="company"/>
                <Header direction={'right'}/>
                <Body />
                <Footer />
            </div>
        </>
    );
}
