import React, { useState } from "react";
import { FontAwesomeIcon } from "../../../../../../node_modules/@fortawesome/react-fontawesome";
import { faXmark } from "../../../../../../node_modules/@fortawesome/free-solid-svg-icons";
import "./ContactForm.scss";

export default function ContactForm({ contactFromCloseClickHandler }) {
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [topic, setTopic] = useState("");
  const [topicOptions] = useState([
    "Easy Project",
    "Salesforce",
    "Nectain",
    "Application Development",
    "Consultations",
    "Other",
  ]);
  const [messageSent, setMessageSent] = useState(false);

  const handleTopicChange = () => (e) => {
    setTopic(e.target.value);
  };

  const handleCompanyChange = () => (e) => {
    setCompany(e.target.value);
  };

  const handleNameChange = () => (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = () => (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = () => (e) => {
    setPhone(e.target.value);
  };

  const handleMessageChange = () => (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => (e) => {
    e.preventDefault();

    fetch("/contact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        form: {
          source: "popup",
          topic: topic,
          company: company,
          name: name,
          email: email,
          phone: phone,
          message: message,
        },
      }),
    }).then(function (response) {
      setMessageSent(true);
    });
  };

  return (
    <div className="contact-form-container contact-form-full-dimension">

      <div className="contact-form-nav">
        <div className="contact-form-toolbar">
          <div className="contact-form-toolbar-button contact-form-flex-centered">
            <FontAwesomeIcon
              className="btn-close"
              icon={faXmark}
              onClick={contactFromCloseClickHandler}
            />
          </div>
        </div>
      </div>

      <div className="contact-form-full-dimension contact-form-absoluted contact-form-flex-centered">
        <div className="form-dark-background-container">
          {!messageSent && (
            <form className="p-3">
              <legend>Leave Your Contact Here</legend>
              <p>and we will help you find the best solution.</p>

              <div className="inputs-container">
                <div className="pt-3 d-flex flex-column align-items-start">
                  <label htmlFor="topic">Topic</label>
                  <select
                    id="topic"
                    name="Topic"
                    value={topic}
                    onChange={handleTopicChange()}
                  >
                    {topicOptions.map((option, index) => {
                      return (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>

                  <label htmlFor="company">Company</label>
                  <input
                    type="text"
                    id="company"
                    name="company_name"
                    value={company}
                    onChange={handleCompanyChange()}
                  />

                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="user_name"
                    value={name}
                    onChange={handleNameChange()}
                  />

                  <label htmlFor="company_email">Company Email</label>
                  <input
                    type="text"
                    id="company_email"
                    name="company_email"
                    value={email}
                    onChange={handleEmailChange()}
                  />

                  <label htmlFor="phone_number">Phone Number</label>
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    value={phone}
                    onChange={handlePhoneChange()}
                  />

                  <label htmlFor="how_we_can_help">How can we help you?</label>
                  <textarea
                    id="how_we_can_help"
                    name="how_we_can_help"
                    value={message}
                    onChange={handleMessageChange()}
                  />

                  <div className="agreement-checkbox pt-3">
                    <input id="checkbox" type="checkbox"/>
                    <label htmlFor="checkbox">
                      {" "}
                      I agree with the processing of my contact data
                    </label>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end col">
                <button
                  type="button"
                  className="contact-form-submit-button mt-3"
                  onClick={handleSubmit()}
                >
                  SEND
                </button>
              </div>
            </form>
          )}

          {messageSent && (
            <div>
              <legend>Thank you! We will contact you soon.</legend>
            </div>
          )}

        </div>
      </div>
    </div>
  );
}
