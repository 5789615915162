export const EasyProjectLang = {
  products: "منتجات",
  title: "Easy Project",
  subTitle: " جعلت إدارة المشروع مرئية وسهلة",
  text: "استنادًا إلى أفضل الممارسات التي تم تجربتها واختبارها ، تتضمن طرق إدارة المشروع السلسه على أفضل أساليب WBS و Gantt و Agile و .Kanban إنه قابل للتوسعة مع الوحدات النمطية للموارد ، والشؤون المالية ، ومكتب المساعدة ، وذكاء الأعمال ، ومجموعة أدوات DevOps.",
  button: "معلومات أكثر",
};

export const TeseyLang = {
  products: "منتجات",
  title: "NECTAIN",
  subTitle: "LOW-CODE platform for BPM, PM and DMS",
  text: "في كل يوم يتربص فيه مجرمو الإنترنت وراء كل زاوية ، من الضروري حماية بياناتك. لهذا السبب تقدم NECTAIN نهجًا معقدًا ومنهجيًا ومتكاملًا لحماية معلوماتك.",
  button: "معلومات أكثر",
};
