import React, { useEffect, useState } from "react";
import NavProton from "../shared/NavBar/NavProton";
import Header from "./Header/Header";
import ItInfrastructure from "./ItInfrastructure/ItInfrastructure";
import Security from "./Security/Security";
import About from "./About/About";
import ReadyToStart from "./ReadyToStart/ReadyToStart";
import Footer from "./Footer/Footer";
import "./services.scss";
import "../../styles/shared.scss";
import { connect } from "react-redux";
import PageDivider from "../shared/PageDivider";

function Services({ lang }) {
  useEffect(() => {
    let p = [].slice.call(document.getElementsByTagName("p"));
    let h1 = [].slice.call(document.getElementsByTagName("h1"));
    let h3 = [].slice.call(document.getElementsByTagName("h3"));
    let container = document.querySelector(".container");
    if (lang === "AR") {
      document.body.style.direction = "rtl";
      h1.forEach((element) => (element.style.textAlign = "right"));
      h3.forEach((element) => (element.style.textAlign = "right"));
      p.forEach((element) => (element.style.textAlign = "right"));
      container.style.position = "relative";
      container.style.left = "-500px";
      h3[0].style.width = "700px";
    } else {
      document.body.style.direction = "ltr";
      h1.forEach((element) => (element.style.textAlign = "left"));
      h3.forEach((element) => (element.style.textAlign = "left"));
      p.forEach((element) => (element.style.textAlign = "left"));
      container.style.left = "0px";
      h3[0].style.width = "auto";
    }
  });

  return (
    <>
      <div
        className={`proton-services-page`}>
        <NavProton selected="services" />

        <Header />
        <ItInfrastructure />
        <PageDivider direction={"left"}/>
        <Security />
        {/*<About />*/}
        {/*<ReadyToStart />*/}
        <Footer />
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Services);
