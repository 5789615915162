import React from 'react';
import "./tesey.scss";
import "./tesey-animation.scss";
import SharedTesey from '../../shared/Tesey/Tesey'


export default function Tesey({ direction }) {
    return (
        <>
            <div className={ `tesey-page tesey-page-scroll-${direction}` }>
                <SharedTesey />
            </div>
        </>
    );
}