import React from "react";
import NavProton from "../shared/NavBar/NavProton";
import "./easy-project.scss";
import Header from "./Header/Header";
import Tools from "./Tools/Tools";
import Services from "./Services/Services";
// import Features from './Features/Features'
import ReadyToStart from "./ReadyToStart/ReadyToStart";
import Integrations from "./Integrations/Integrations";
import CertifiedPartner from "./CertifiedPartner/CertifiedPartner";
import Trial from "./Trial/Trial";

import "../../styles/shared.scss";

export default function EasyProject() {
    let lang = "EN"
  return (
    <>
      <div className="easy-project-page">
        <NavProton whiteBackground={true} selected="products" />

        <Header language={lang} />
        <Tools language={lang} />
        <Services />
        {/* <Features /> */}
        <ReadyToStart />
        <Integrations />
        <CertifiedPartner />
        <Trial />
      </div>
    </>
  );
}
